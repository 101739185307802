<template>
    <div class="Products">
        <Nav
            nav-title="软件产品"
            :pic-url="picUrl"
        >
            <ul
                class="left-nav-list"
            >
                <li
                    v-for="(item,index) in productsList"
                    :key="index"
                    @click="changeTab(index,item.tabIndex)"
                    :class="{selectedLi:selectedIndex=== index}"
                >
                    <router-link :to="item.url">
                        <span>{{ item.name }}</span>
                        <i class="iconfont icon-youjiantou" />
                    </router-link>
                </li>
            </ul>
        </Nav>
    </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
export default {
  name: 'Products',
  components: {
      Nav
  },
  data () {
        return {
          picUrl: require('../assets/images/bgImg/ruanjianchanpin.png'),
          productsList: [
            {
              url: '/products/femtcad',
              name: 'FemTCAD',
              tabIndex: '2-1'
            },
            {
              url: '/products/shine',
              name: 'SHINE',
               tabIndex: '2-2'
            },
            {
              url: '/products/sigsimu',
              name: 'SigSimu',
               tabIndex: '2-3'
            },
            {
              url: '/products/vgms',
              name: 'VGMS',
               tabIndex: '2-4'
            }
          ],
          selectedIndex: -1
        }
  },
  methods: {
      changeTab (index, tabIndex) {
        this.$store.commit('changeTopNav', tabIndex)
        this.selectedIndex = index
      }
  },
  watch: {
    '$store.state.selectedTabIndex': function (newVal, oldVal) {
        if (newVal === '2-1') {
           this.selectedIndex = 0
        } else if (newVal === '2-2') {
           this.selectedIndex = 1
        } else if (newVal === '2-3') {
           this.selectedIndex = 2
        } else if (newVal === '2-4') {
           this.selectedIndex = 3
        }
    }
  },
  created () {
     if (this.$store.state.selectedTabIndex === '2-1') {
       this.selectedIndex = 0
     } else if (this.$store.state.selectedTabIndex === '2-2') {
       this.selectedIndex = 1
     } else if (this.$store.state.selectedTabIndex === '2-3') {
       this.selectedIndex = 2
     } else if (this.$store.state.selectedTabIndex === '2-4') {
       this.selectedIndex = 3
     }
  }
}
</script>
<style lang="less" scoped>
.Products{
  .left-nav-list{
    list-style: none;
    padding-left: 0;
    margin-bottom: 3.125rem;
    li{
      width: 100%;
      padding: 0.625rem;
      border-bottom: 1px solid #f2f2f2;
      a{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        color: rgba(0,0,0,.65);
        i{
          color: rgba(0,0,0,.65);
          font-size: 1.125rem;
        }
        span{

        }
      }
    }
    .selectedLi{
      background: rgb(245, 247, 250);
      border-radius: 5px;
      span{
        font-weight: 600;
      }
    }
  }
}
</style>
